import React from 'react';
import Navbar from '../pages/Navbar';
import '../styles/AboutMe.css';

import laptop from '../assets/Programming.jpg'

const AboutMe = () => {
    return (<>
        <Navbar />
        <main className='about-main'>
            <img src={laptop} alt= 'Coding Laptop' className='about-img'/>
            <h1>About Me</h1>
            <p>This is my personal website.</p>
            <h2>My Skills</h2>
            <ul className='skill-list'>
                <Item>Java</Item>
                <li>Java</li>
                <li>JavaScript</li>
                <li>React</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>SQL</li>
                <li>Python</li>
                <li>Git</li>
            </ul>
            <h2>Education</h2>
            <p>Studying a Bachelor's Degree in Software Engineering</p>
            <h2>Experience</h2>
            <p>5 years of professional OOP development experience</p>
        </main>
    </>);
};

class Item extends React.Component {
    render() {
        return (
            <li>{this.props.children}</li>
        );
    }
}

export default AboutMe;