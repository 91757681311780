// In components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Correct static path import

import '../styles/Navbar.css'; // Import your CSS file
import '@fortawesome/fontawesome-free/css/all.min.css';


const Navbar = () => {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
    <nav className="navbar">
        <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={logo} alt="Isa Logo" height="55" />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
            <Link to="/" className={click ? "nav-links active" : "nav-links"} onClick={closeMobileMenu}>
                Home
            </Link>
            </li>
            <li className="nav-item">
            <Link to="/about-me" className={click ? "nav-links active" : "nav-links"} onClick={closeMobileMenu}>
                About Me
            </Link>
        </li>
        <li className="nav-item">
            <Link to="/contact" className={click ? "nav-links active" : "nav-links"} onClick={closeMobileMenu}>
                Contact
            </Link>
        </li>
        </ul>
        </div>
    </nav>
    );
};

export default Navbar;