import Navbar from "./Navbar";
import '../styles/Contact.css'

/**
 * Renders the Contact page.
 * @returns {JSX.Element} The Contact component.
 */
const Contact = () => {
    return (
        <>
            <Navbar/>
            <main className="contact-main">
                <div className="contact-grid">
                    <ContactSection 
                        title="Email"
                        text="Feel free to send me an email. I will respond when I am next available."
                        link={{
                            href: "mailto:contact@isaac-broadbent.uk",
                            text: "contact@isaac-broadbent.uk",
                        }}
                    />
                    <ContactSection
                        title="LinkedIn"
                        link={{
                            href: "https://www.linkedin.com/in/isaac-broadbent-1a4566264/",
                            text: "Profile Link"
                        }}
                        text="My LinkedIn profile, with prior work experience,
                        grades and skills."
                    />
                </div>
            </main>
        </>
    );
}

/**
 * Renders a section of the Contact page.
 * @param {string} props.title - The title of the section.
 * @param {Object} props.link - The link object.
 * @param {string} props.text - The text content of the section.
 * @returns {JSX.Element} The ContactSection component.
 */
function ContactSection({title = "", link = {}, text = ""}) {
    return (
        <div className="contact-section">
            <h1 className="section-title">{title}</h1>
            <a 
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            >
                {link.text}
            </a>
            <p className="contact-text">
                {text}
            </p>
        </div>
    );
}

export default Contact;