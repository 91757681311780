import '../styles/App.css';

import React from 'react';
import Navbar from './Navbar';
import Stars from './Stars';

function App() {
    

    return (
        <div className="App">
        <Stars />
        <Navbar />
        <header className="App-header">
            <h1 className='Title'>Isa's Website</h1>
            <h2 className='Title'>Software Engineering Student</h2>
            <a
            className="App-link"
            href="https://github.com/NinjaMandalorian/"
            target="_blank"
            rel="noopener noreferrer"
            >
                Github Profile
            </a>
        </header>
    </div>
);
}

export default App;
