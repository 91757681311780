import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import App from './pages/App';
import PageNotFound from './pages/PageNotFound'
import Star from './pages/Stars'
import Contact from './pages/Contact';
import AboutMe from './pages/AboutMe';

import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <Routes>
        <Route exact path="/" element ={<App />} />
        <Route path="/about-me" element={<AboutMe />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route exact path="/stars" element={<Star />}/>
        <Route path="*" element={<PageNotFound />}/>
    </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
