import Navbar from './Navbar';
import '../styles/App.css';

const PageNotFound = () => {
    return (
        <div>
            <Navbar />
            <h1 style={
                {color:"#ffffff", textAlign: "center", 
                position: "absolute", top: "50%", width: "100%", maxWidth: "100vw"}}
            >
                Error 404: Page Not Found<br/>🤔
            </h1>
        </div>
    );
}

export default PageNotFound;